export const TOGGLE_CLASSES = {
  ACTIVE: 'is-active',
  INACTIVE: 'is-inactive',
};

export const SUBNAV_CLASSES = {
  VISIBLE: 'submenu-is-active',
  HIDDEN: 'submenu-is-hidden',
};

export const HEADER_TRANSPARENT_BACKGROUND_CLASS = 'header_transparent-background';
