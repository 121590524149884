import TrayNavigation from './module-tray-navigation';

class Tray {
  constructor($el) {
    this.$el = $el;
    this.$scrollable = this.$el.find('[data-scroll]');

    const trayNavigation = new TrayNavigation(this.$el.find('.js-traynav'));

    this.$el.find('[data-close]').on('click', () => this.close());
    this.$el.addClass('is-loaded');
  }

  scrollToBottom() {
    // eslint-disable-next-line no-magic-numbers
    this.$scrollable.animate({ scrollTop: this.$scrollable[0].scrollHeight }, 500);
  }

  open() {
    $('body').css('top', `-${window.scrollY}px`).addClass('tray-is-active');
    this.$el.addClass('is-active').attr('aria-hidden', 'false');
  }

  close() {
    const scrollY = document.body.style.top;

    $('body').css('top', '').removeClass('tray-is-active');
    $('#js-rooster-burger').attr('aria-expanded', 'false');
    this.$el.attr('aria-hidden', 'true').removeClass('is-active');

    window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
  }
}

export default Tray;
