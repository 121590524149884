class TrayNavigation {
  constructor($el) {
    $el.find('.sub-menu').each((i, list) => {
      // const $list = $(this);
      const $link = $(list).siblings('a');

      $(list).prepend(`<li class="menu-item">${$link[0].outerHTML}</li>`);

      $link.removeAttr('onclick');

      $link.on('click', (ev) => this.toggleSubnavigation(ev));
    });
  }

  toggleSubnavigation(ev) {
    ev.preventDefault();

    const $parent = $(ev.currentTarget).closest('li');

    $parent.toggleClass('submenu-is-active');
  }
}

export default TrayNavigation;
