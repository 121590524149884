import LocalStorage from 'js/services/localStorage';
import { RCLID_TRACKING_PARAMS } from 'js/constants/TrackingConstants';
import { CONFIG } from 'js/constants/Config';
import RequestService from 'js/services/requestService';
import { LocalStorageKeys } from 'js/constants/LocalStorageConstants';

const TrackingRM = {
  getTrackingQueryString() {
    const searchParams = new URLSearchParams(window.location.search);
    const trackingParams = new URLSearchParams();

    searchParams.forEach((value, key) => {
      if (RCLID_TRACKING_PARAMS.includes(key)) {
        trackingParams.set(key, value);
      }
    });

    return trackingParams.toString();
  },

  fetchRmClid(callback) {
    const query = this.getTrackingQueryString();

    if (query.length) {
      RequestService.post(`${CONFIG.BASE_URL}${CONFIG.RMCLID_ENDPOINT}?${query}`).then((data) => {
        const rmclid = data.rmclid;
        this.storeRmclid(rmclid);
        callback(rmclid);
      });
    }

    return callback();
  },

  storeRmclid(rmclid) {
    if (!rmclid) {
      return;
    }

    LocalStorage.storeItem(LocalStorageKeys.ROOSTER_RMCLID, rmclid);
  },

  getFromLocalStorage() {
    return LocalStorage.getItem(LocalStorageKeys.ROOSTER_RMCLID);
  },
};

export default TrackingRM;
