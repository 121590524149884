/* eslint-disable */
import _ from 'underscore';

(function (window, document, undefined) {
  /*
   * Global api.
   */
  const unveil = {
    get() {
      return _instance;
    },

    // Main entry point.
    init($elem, options) {
      return new Unveil($elem, options);
    },
  };

  /**
   * @var {Number}	Counter, used to create unique event names.
   */
  let count = 0;
  /**
   * Generates a custom scroll + resize
   * event namespace
   *
   * @return {String}			The namespace
   */
  const generateEventNameSpace = function () {
    // eslint-disable-next-line no-plusplus
    return `scroll.${++count} resize.${count}`;
  };

  /**
   * Constructor.
   */
  function Unveil($elem, options) {
    if (_.isUndefined($elem)) {
      return false;
    }

    options = options || {};

    /**
     * jQuery window object
     * @type {jQuery}
     */
    const $window = $(window);
    /**
     * An unique namespaced event used for scroll + window.resize to allow
     * easy event removal when this instance has served it's purpose or is
     * no longer needed.
     * @type {String}
     */
    const eventName = generateEventNameSpace();
    /**
     * The name of the event of a previous instance
     * with the element, if it exists.
     * @type {String|Undefined}
     */
    const oldEventName = $elem.data('waitInstance');

    // If the element passed already has a "waitInstance" data attribute,
    // this means an event has already been created for it, so remove the event
    if (oldEventName && typeof oldEventName === 'string') {
      $window.off(oldEventName);
    }

    // Set the waitInstance on the dom element for future checks
    $elem.data('waitInstance', eventName);

    /**
     * Checks if the element is in view
     */
    const checkVisible = function () {
      const windowTop = $window.scrollTop();
      const windowBottom = windowTop + $window.height();
      const elementTop = $elem.offset().top;
      const elementBottom = elementTop + $elem.height();
      const offset = options.offset || 50;

      // If any part of the element is within the browser's viewport
      if (elementBottom + offset >= windowTop && elementTop - offset <= windowBottom) {
        if ($elem.is(':hidden')) {
          return;
        } // Don't do anything if it's a hidden element

        // Trigger a "revealed" event on the element
        $elem.addClass('is-visible');

        // Remove it's data attribute, it's no longer needed
        $elem.removeData('waitInstance');

        // Remove the event listener for this instance
        $window.off(eventName);

        // If a callback was passed, call it
        if (typeof options.callback === 'function') {
          options.callback.call(options.context || window);
        }
      }
    };

    // When the browser is scrolled or resized, check the element's visibility
    $window.on(eventName, _.throttle(checkVisible, 100));

    // And check it right now too
    checkVisible();
  }

  window.unveil = unveil;
})(window, document);
