import { SUBNAV_CLASSES } from 'js/constants/header-constants';

class HeaderNavigation {
  constructor(opts) {
    const that = this;
    const { $el } = opts;

    that.opts = opts;
    that.$el = $el;
    that.$links = $el.find('.js-primary-navigation .menu-item');
    that.$subnav = $('#js-subnavigation');
    that.$subnavOptions = that.$subnav.find('.js-submenu-item');

    if (this.opts.hasVisibleSubnav) {
      that.$el.addClass(SUBNAV_CLASSES.VISIBLE);
    }

    that.$links.on('mouseenter', (ev) => that.toggleNavOption(ev));
    that.$links.on('mouseleave', (ev) => that.removeActive(ev));

    // Hide the active option when leaving the header...
    $el.on('mouseleave', () => that.resetNavOptions());
  }

  getNavOption(key) {
    return this.$subnavOptions.filter((i, obj) => {
      return $(obj).data('obj') === key;
    });
  }

  toggleNavOption(ev) {
    const $hovered = $(ev.currentTarget);

    this.$links.removeClass('is-active').addClass('is-inactive');
    $hovered.addClass('is-active').removeClass('is-inactive');

    if (!$hovered.hasClass('menu-item-has-children')) {
      this.hideSubnav();
    }

    if ($(ev.currentTarget).hasClass('menu-item-has-children')) {
      this.showNavOption(ev);
    } else {
      this.hideNavOption();
    }
  }

  showNavOption(ev) {
    const key = $(ev.currentTarget).data('key');
    const $active = this.getNavOption(key);

    this.$el.addClass(SUBNAV_CLASSES.VISIBLE).removeClass(SUBNAV_CLASSES.HIDDEN);
    this.$subnavOptions.addClass('is-hidden').removeClass('is-active');

    $active.removeClass('is-hidden').addClass('is-active');
  }

  isAtTop() {
    return this.$el.find('.is-top').length;
  }

  hideNavOption() {
    if (!this.opts.hasVisibleSubnav) {
      if (this.isAtTop()) {
        this.$el.addClass(SUBNAV_CLASSES.HIDDEN);
      }

      this.$subnavOptions.removeClass('is-hidden');
    } else {
      this.$subnavOptions.removeClass('is-active is-hidden');
    }
  }

  hideSubnav() {
    if (!this.opts.hasVisibleSubnav) {
      this.$el.removeClass(SUBNAV_CLASSES.VISIBLE);
    }
  }

  resetNavOptions() {
    this.hideNavOption();
    this.hideSubnav();

    this.$links.removeClass('is-active is-inactive');
  }

  removeActive(ev) {
    const $this = $(ev.currentTarget);

    if (!$this.hasClass('menu-item-has-children')) {
      $this.removeClass('is-active');
    }
  }
}

export default HeaderNavigation;
