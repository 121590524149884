class Burger {
  constructor($el) {
    $el.on('click', function (e) {
      e.preventDefault();

      const toggled = new Event('burger_onClick', {
        bubbles: true,
      });

      $el.attr('aria-expanded', 'true');

      $el[0].dispatchEvent(toggled);
    });
  }
}

export default Burger;
