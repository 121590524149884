class ConfigClass {
  constructor() {
    this.config = $('#js-rooster-config').data('config');
  }

  getGaApiKey() {
    return this.config.googleApi.google_analytics_api_key;
  }

  getFbApiKey() {
    return this.config.facebookApi;
  }

  showIntercom() {
    return this.config.showIntercom;
  }

  userCameFromApp() {
    return this.config.userCameFromApp;
  }

  getReferral() {
    return this.config.referral;
  }
}

const Config = new ConfigClass();

Object.freeze(Config);

export default Config;
