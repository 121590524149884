import LocalStorage from 'js/services/localStorage';
import { LocalStorageKeys } from 'js/constants/LocalStorageConstants';
import { REFERRALS } from 'js/constants/ReferralConstants';
import Config from 'js/services/config';

const ReferralRM = {
  getReferral() {
    let referral = Config.getReferral();

    if (referral) {
      this.setToLocalStorage(referral);
    } else {
      referral = this.getFromLocalStorage();
    }

    return referral;
  },

  setToLocalStorage(referral) {
    if (referral) {
      LocalStorage.storeItem(
        LocalStorageKeys.ROOSTER_REFERRAL,
        JSON.stringify({
          value: referral,
          date: Date.now(),
        })
      );
    }
  },

  getFromLocalStorage() {
    let referral = LocalStorage.getItem(LocalStorageKeys.ROOSTER_REFERRAL);

    if (!referral) {
      return;
    }
    referral = JSON.parse(referral);

    const dateNow = new Date();
    const daysAgoDate = dateNow.setDate(dateNow.getDate() - REFERRALS.REFERRAL_LENGTH_DAYS);
    const storedDate = referral.date;

    if (storedDate < daysAgoDate) {
      return LocalStorage.removeItem(LocalStorageKeys.ROOSTER_REFERRAL);
    }

    return referral.value;
  },
};

export default ReferralRM;
