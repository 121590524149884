import Cookie from 'js/services/cookie';
import { COOKIES } from 'js/constants/cookies-constants';

class Popover {
  constructor($el, setCookie) {
    this.$el = $el;
    this.setCookie = setCookie;

    this.$el.find('[data-dismiss]').on('click', () => this.hide());
  }
  // eslint-disable-next-line no-magic-numbers
  delayShow(delay = 500) {
    const that = this;
    setTimeout(function () {
      that.show();
    }, delay);
  }

  show() {
    this.$el.addClass('is-active');
  }

  hide() {
    const id = this.$el.data('popover').toLowerCase();

    this.$el.removeClass('is-active');

    if (this.setCookie) {
      Cookie.set(`${COOKIES.ROOSTERMONEY_POPOVER_DISMISSED}_${id}`, true);
    }
  }
}

export default Popover;
