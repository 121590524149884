export const TRACKING_QUERY_KEY = 'tr_';

export const ROOSTER_TRACKING_KEY = 'rclid';

export const IMPACT_RADIUS_CLICK_ID = ['irClickId', 'irclickid'];

export const RCLID_TRACKING_PARAMS = [
  'utm_source',
  'utm_content',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'gaid',
  'iad',
  'gclid',
  'fbclid',
  'clickId',
  ...IMPACT_RADIUS_CLICK_ID,
];

export const OneTrustCategories = {
  STRICTLY_NECESSARY: 'C0001',
  PERFORMANCE: 'C0002',
  FUNCTIONAL: 'C0003',
  TARGETING: 'C0004',
};
