class Modal {
  constructor($el) {
    this.$el = $el;

    this.$el
      .addClass('is-visually-hidden')
      .append('<div class="modal-base_background-close" data-close />')
      .appendTo($('body'));

    this.$el.find('[data-close]').on('click', () => this.hide());

    // Get any triggers for this modal and attach a click event
    $(`[data-modal=${this.$el.attr('id')}]`).on('click', (ev) => this.show(ev));
  }

  needsLazyLoad() {
    return this.$el.find('[data-src]');
  }

  lazyLoad() {
    if (!this.needsLazyLoad()) {
      return;
    }

    this.$el.find('[data-src]').each((i, obj) => {
      const $obj = $(obj);

      $obj.attr('src', $obj.data('src'));

      $obj.removeAttr('data-src');
    });
  }

  show(ev) {
    const that = this;
    ev.preventDefault();

    that.lazyLoad();

    $('body').css('top', `-${window.scrollY}px`).addClass('modal-is-active');

    that.$el.removeClass('is-hidden');

    setTimeout(function () {
      that.$el.removeClass('is-visually-hidden');
      // eslint-disable-next-line no-magic-numbers
    }, 20);
  }

  hide() {
    const scrollY = document.body.style.top;

    $('body').css('top', '').removeClass('modal-is-active');

    this.$el.addClass('is-visually-hidden');

    window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);

    setTimeout(() => {
      this.$el.addClass('is-hidden');

      if (this.$el.find('iframe')) {
        const $frame = this.$el.find('iframe');
        const autoplay = 'autoplay=1';
        let url = $frame.attr('src');

        if (url.includes(autoplay)) {
          url = url.replace(autoplay, '');
        }

        $frame.attr('src', url);
      }
      // eslint-disable-next-line no-magic-numbers
    }, 800);
  }
}

export default Modal;
