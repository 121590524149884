export const ALERT_EVENTS = {
  SHOW: 'showAlert',
};

export const ALERT_TYPES = {
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
  SUCCESS: 'success',
};

export const ALERT_ID = 'site-alert';
