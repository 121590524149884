export const COOKIES = {
  /**
   * Popups Tracks site popover dismissals
   */
  POPUP_DISMISS: 'popup_dismiss',

  /**
   * Popovers
   */
  ROOSTERMONEY_POPOVER_DISMISSED: 'roostermoney_popover_dismissed',
};
