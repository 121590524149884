import $ from 'jquery';
import { IMPACT_RADIUS_CLICK_ID, RCLID_TRACKING_PARAMS } from 'js/constants/TrackingConstants';
import { getQueryParamStringFromUrl } from 'js/helpers/queryParams';

export const appendTrackingToUrls = ($el, params) => {
  if (Object.keys(params).length === 0) {
    return;
  }

  const windowUrlParams = new URLSearchParams(window.location.search);
  const urlParams = new URLSearchParams(params);

  windowUrlParams.forEach((value, key) => {
    if (IMPACT_RADIUS_CLICK_ID.includes(key) || !RCLID_TRACKING_PARAMS.includes(key)) {
      urlParams.set(key, value);
    }
  });

  $el.find('a[href]').each((key, ele) => {
    const $cta = $(ele);
    const url = $cta.attr('href');

    if (url.length && url.includes('app.roostermoney.com')) {
      const urlObject = new URL(url);
      const { origin, pathname } = urlObject;

      const ctaQueryParams = new URLSearchParams(getQueryParamStringFromUrl(url));
      const combinedQueryParams = new URLSearchParams({
        ...Object.fromEntries(ctaQueryParams),
        ...Object.fromEntries(urlParams),
      });

      $cta.attr('href', `${origin}${pathname}?${combinedQueryParams.toString()}`);
    }
  });
};
