import _ from 'lodash';
import { getQueryParamStringFromUrl, getParamsObjectFromQueryString } from 'js/helpers/queryParams';
import { TRACKING_QUERY_KEY } from '../constants/TrackingConstants';

const TrackingGA = {
  /**
   * @param category: Event category	A group of objects, or a group of similar events
   * @param action:   Event action	The type of interaction, usally a click, or scroll
   * @param label:    Event label (optional)	Additional information about the event action,
   *                                          often the name of the video clicked, or name of file downloaded
   **/
  trackEvent(label, action, category, value, event) {
    window.dataLayer = window.dataLayer || [];

    const data = {
      event: category,
      eventAction: action ? action : 'click',
      eventLabel: label,
      eventValue: value,
    };

    window.dataLayer.push(data);
  },

  addTrackingToContentLinks() {
    $('a[href]').each((i, el) => {
      const $el = $(el);
      const url = $el.attr('href');
      const cleanUrl = url.split('?')[0];

      if (url.includes(TRACKING_QUERY_KEY)) {
        const params = getParamsObjectFromQueryString(getQueryParamStringFromUrl(url));
        const cleanObj = {};

        _.forEach(params, (value, key) => {
          if (key.includes(TRACKING_QUERY_KEY)) {
            cleanObj[key.replace(TRACKING_QUERY_KEY, '')] = value;

            delete params[key];
          }
        });

        $el.attr('href', `${cleanUrl}?${$.param(params)}`);

        if (!_.isEmpty(cleanObj)) {
          $el.attr('data-tracking', JSON.stringify(cleanObj));
        }
      }
    });
  },

  instantiateTrackingEvents() {
    this.addTrackingToContentLinks();

    $('[data-tracking]').each((i, el) => {
      const $el = $(el);
      const data = $el.data('tracking');

      $el.on('click', (ev) => {
        TrackingGA.trackEvent(data.label, data.action, data.category, data.value);
      });
    });
  },
};

export default TrackingGA;
