import _ from 'underscore';
import { HEADER_TRANSPARENT_BACKGROUND_CLASS } from 'js/constants/header-constants';

class AffixHeader {
  constructor(opts) {
    const that = this;
    const { $el, hasVisibleSubnav } = opts;

    that.$el = $el;
    that.hasVisibleSubnav = hasVisibleSubnav;
    that.hasTransparentBackground = that.$el.hasClass('header_has-transparent-background');
    that.$affixed = $el.find('[data-affix]');

    const height = that.hasVisibleSubnav ? that.$affixed.outerHeight() : $el.find('.js-header-main').outerHeight();

    that.opts = {
      headerHeight: height,
      lastPosition: 0,
      tolerance: 200,
    };

    that.$affixed.addClass('is-fixed is-top');

    that.$affixed.wrapInner('<div class="affix-container"></div>');

    if (!that.hasTransparentBackground) {
      $el.css('padding-top', that.opts.headerHeight);
    }

    // eslint-disable-next-line no-magic-numbers
    $(window).scroll(_.throttle(() => that.scrolling(), 100));
  }

  scrolling() {
    const posTop = this.$affixed.offset().top;
    const height = {
      window: $(window).height(),
      document: $(document).height(),
    };
    const isBottom = posTop >= height.document - height.window - this.opts.headerHeight;
    const topCondition = this.hasTransparentBackground ? 0 : this.$el.outerHeight();

    if (posTop <= topCondition) {
      this.$affixed.addClass('is-top').removeClass('is-hidden');

      if (this.hasTransparentBackground && !this.$el.is(':hover')) {
        this.$el.addClass(HEADER_TRANSPARENT_BACKGROUND_CLASS);
      }
    } else {
      this.$affixed.removeClass('is-top');
      if (this.hasTransparentBackground) {
        this.$el.removeClass(HEADER_TRANSPARENT_BACKGROUND_CLASS);
      }

      // Scrolling up
      if (isBottom || posTop < this.opts.lastPosition) {
        this.$affixed.addClass('is-visible').removeClass('is-hidden');
        // Scrolling down
      } else {
        if (!this.$el.is(':hover')) {
          this.$affixed.addClass('is-hidden').removeClass('is-visible');
        }
      }

      if (
        posTop > this.opts.lastPosition - this.opts.tolerance &&
        posTop < this.opts.lastPosition + this.opts.tolerance
      ) {
        return;
      }

      this.opts.lastPosition = posTop;
    }
  }
}

export default AffixHeader;
