export default {
  DELETE: 'delete',
  GET: 'get',
  PATCH: 'patch',
  POST: 'POST',
  PUT: 'put',
  CONTENT_TYPE: {
    FORM_URL_ENCODED: 'application/x-www-form-urlencoded',
    IMG_PNG: 'image/png',
    IMG_JPEG: 'image/jpeg',
    JSON: 'application/json',
  },
  RESPONSE_TYPE: {
    TEXT: 'text',
    JSON: 'json',
  },
  SERIALISER: {
    JSON: 'json',
    RAW: 'raw',
    URL_ENCODED: 'urlencoded',
  },
};
