class Accordion {
  constructor($el) {
    this.$el = $el;

    this.$el.find('[data-toggle]').click((ev) => this.toggle(ev));
  }

  toggle(ev) {
    const $item = $(ev.currentTarget).parent();

    ev.preventDefault();

    if ($item.hasClass('is-active')) {
      this.close($item);
    } else {
      this.open($item);
    }
  }

  open($ele) {
    $ele.find('[aria-expanded]').attr('aria-expanded', 'true');
    $ele.addClass('is-active');
  }

  close($ele) {
    $ele.find('[aria-expanded]').attr('aria-expanded', 'false');
    $ele.removeClass('is-active');
  }
}

export default Accordion;
