// Node Dependancies
import modernizr from 'modernizr';

// Modules
import Page from 'js/modules/module-page';
import Modal from 'js/modules/module-modal';

// Plugins
import 'js/plugins/lazyload.js';

// Site CSS
import 'sass/app.scss';

jQuery(function ($) {
  // ON DOCUMENT READY
  $(document).ready(async function () {
    new Page($('#body'));

    if ($('[data-rooster-modal]').length) {
      $('[data-rooster-modal]').each((i, modal) => {
        new Modal($(modal));
      });
    }

    if ($('.roostermoney__table').length) {
      // Get the plan button.
      const $plan = $('.roostermoney__table .thead .plan-button');

      $plan.on('click', function (e) {
        e.preventDefault();
        // Get button's data-target - it's a class for <tbody> <td>.
        const $target = $(this).data('target');
        // Exchange classes for plan buttons.
        $(this).removeClass('inactive').addClass('active').siblings().removeClass('active').addClass('inactive');

        // Exchange classes for feature indicators.
        $(`.roostermoney__table--table tbody td.${$target}`)
          .removeClass('inactive')
          .addClass('active')
          .siblings('td')
          .removeClass('active')
          .addClass('inactive');
      });
    }

    if ($('.copybutton').length) {
      new ClipboardJS('.copybutton');
    }

    if ($('#select-chore').length) {
      const $trigger = $(this).find('.trigger');

      $trigger.on('click', function (e) {
        e.preventDefault();
        $('#select-chore ul').toggleClass('open');
      });
    }

    if ($('#videoModal').length) {
      const $modalClose = $('#videoModal').find('.close-reveal-modal');
      const $modalIframe = $('#videoModal').find('iframe');

      $modalClose.on('click', function (e) {
        $($modalIframe).attr('src', $($modalIframe).attr('src'));
      });
    }
  }); // end of document ready

  /* Added by Personas */
  (function () {
    // Add input field for family emails

    function buildCheckboxElement(userId, deviceId, deviceLabel) {
      return (
        '<div class="custom-checkbox">' +
        `<label for="fEmail${userId}_device${deviceId}">${deviceLabel}</label>` +
        `<input type="checkbox" name="fEmail${userId}_device${deviceId}" value="ticked">` +
        '<span class="custom-box"></span>' +
        '</div>'
      );
    }

    function buildFormInput(userId) {
      const devices = {
        ios: 1,
        android: 2,
        kindle: 3,
        other: 4,
      };
      return (
        '<div class="family-email mob-center">' +
        `<input type="email" name="fEmail${userId}" placeholder="Email">
          ${buildCheckboxElement(userId, devices.ios, 'iPhone/iPad')}
          ${buildCheckboxElement(userId, devices.android, 'Android')}
          ${buildCheckboxElement(userId, devices.kindle, 'Kindle')}
          ${buildCheckboxElement(userId, devices.other, 'Other')}
          <div class="clear"></div>` +
        '</div>'
      );
    }

    const addEmailButton = $('.add-email');
    const MAX_ADDITIONAL_FAMILY_EMAILS_COUNT = 3;

    $(buildFormInput(1)).insertBefore(addEmailButton);

    addEmailButton.on('click', function () {
      const numberOfInputs = $('.family-email').length;
      const newInputElement = $(buildFormInput(numberOfInputs + 1));

      if (numberOfInputs <= MAX_ADDITIONAL_FAMILY_EMAILS_COUNT) {
        newInputElement.insertBefore(addEmailButton);

        if (numberOfInputs === MAX_ADDITIONAL_FAMILY_EMAILS_COUNT) {
          addEmailButton.remove();
        }
      }
    });
  })();
});
