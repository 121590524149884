import Tray from './module-tray';
import Burger from './module-burger';
import HeaderNavigation from './module-header-navigation';
import AffixHeader from './module-affix-header';
import { SUBNAV_CLASSES } from 'js/constants/header-constants';

class Header {
  constructor($el) {
    this.$el = $el;

    const hasVisibleSubnav = this.hasVisibleSubnav();
    const tray = new Tray($('#js-rooster-tray'));
    new Burger($el.find('#js-rooster-burger'));
    new HeaderNavigation({ $el, hasVisibleSubnav });
    new AffixHeader({ $el, hasVisibleSubnav });

    this.$el[0].addEventListener('burger_onClick', () => tray.open());

    if (!hasVisibleSubnav) {
      this.$el.addClass(SUBNAV_CLASSES.HIDDEN);
    }

    if (this.$el.data('transparent')) {
      this.$el.on('mouseenter', () => this.removeTransparentHeaderClass());
      this.$el.on('mouseover', () => this.removeTransparentHeaderClass());
      this.$el.on('mouseleave', () => this.addTransparentHeaderClass());

      if ($(window).scrollTop() >= 1) {
        this.removeTransparentHeaderClass();
      }
    }
  }

  hasVisibleSubnav() {
    const $links = this.$el.find('#js-subnavigation .js-submenu-item');
    const activeClasses = [
      'current-menu-item',
      'current-page-item',
      'current_page_item',
      'current-menu-parent',
      'current-menu-ancestor',
    ];
    let hasVisibleSubnav;

    $links.each((i, link) => {
      if (hasVisibleSubnav) {
        return;
      }

      const classes = link.classList;

      for (let i = 0; i < classes.length; i++) {
        hasVisibleSubnav = activeClasses.includes(classes[i]);

        if (hasVisibleSubnav) {
          break;
        }
      }
    });

    return hasVisibleSubnav;
  }

  headerIsTop() {
    return this.$el.find('.is-top[data-affix]').length;
  }

  removeTransparentHeaderClass() {
    // eslint-disable-next-line no-magic-numbers
    return this.$el.delay(800).removeClass('header_transparent-background');
  }

  addTransparentHeaderClass() {
    const that = this;

    if (this.headerIsTop()) {
      return setTimeout(() => {
        that.$el.addClass('header_transparent-background');
        // eslint-disable-next-line no-magic-numbers
      }, 500);
    }
  }
}

export default Header;
