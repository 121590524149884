export const getQueryParamStringFromUrl = (url) => {
  if (url.includes('?')) {
    return `?${url.split('?')[1]}`;
  }

  return '';
};

export const getParamsObjectFromQueryString = (string) => {
  return string
    .replace('?', '')
    .split('&')
    .map((param) => param.split('='))
    .reduce((values, [key, value]) => {
      values[key] = value;
      return values;
    }, {});
};

export const removeQueryParamsFromURL = (url) => {
  return `${url.split('?')[0]}`;
};
