import { ALERT_EVENTS, ALERT_ID } from 'js/constants/alert-constants';

const AlertService = {
  initialize() {
    const ele = document.querySelector('body');

    ele.addEventListener(ALERT_EVENTS.SHOW, (params) => this.showAlert(params));
  },

  addAlert(callback, params) {
    const $body = $('body');
    const { theme, text } = params;
    const $alert = $(
      // eslint-disable-next-line max-len
      `<div id="${ALERT_ID}" class="site-alert site-alert_${theme}"><div class="site-alert_container">${text}</div></div>`
    );

    $body.append($alert);

    setTimeout(() => {
      $alert.addClass('is-active');
      $alert.on('transitionend webkitTransitionEnd oTransitionEnd', () => callback($alert));
      // eslint-disable-next-line no-magic-numbers
    }, 50);
  },

  removeAlert($alert) {
    const $alertClone = $alert || $(`#${ALERT_ID}`);

    if (!$alertClone) {
      return;
    }
    $alertClone.removeClass('is-active');
    $alertClone.on('transitionend webkitTransitionEnd oTransitionEnd', () => $alertClone.remove());
  },

  showAlert(event) {
    this.addAlert(($alert) => {
      // eslint-disable-next-line no-magic-numbers
      setTimeout(() => this.removeAlert($alert), 2000);
    }, event.detail);
  },
};

export default AlertService;
