import Request from 'js/constants/Request';

const RequestService = {
  makeRequest(method, url, params) {
    return new Promise((resolve, reject) => {
      let headers = {
        'Content-Type': Request.CONTENT_TYPE.JSON,
      };
      const paramsToken = params && params.token;
      if (paramsToken) {
        params.token = undefined;
      }
      const error = (params && params.error) || undefined;
      const success = (params && params.success) || undefined;
      if (params && params.headers) {
        headers = {
          ...headers,
          ...params.headers,
        };
      }
      const connParams = {
        ...params,
        headers,
        method,
      };

      return fetch(url, connParams)
        .then((response) => response.json())
        .then((data) => {
          resolve(success ? success(data) : data);
        })
        .catch((err) => {
          error ? error(err) : reject(err);
        })
        .finally(() => {
          params && params.complete && params.complete();
        });
    });
  },

  get(url, params) {
    return this.makeRequest(Request.GET, url, params);
  },

  delete(url, params) {
    return this.makeRequest(Request.DELETE, url, params);
  },

  post(url, params) {
    return this.makeRequest(Request.POST, url, params);
  },

  patch(url, params) {
    return this.makeRequest(Request.PATCH, url, params);
  },

  put(url, params) {
    return this.makeRequest(Request.PUT, url, params);
  },
};

export default RequestService;
