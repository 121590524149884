import 'js/plugins/unveil.js';

function switchUrls($img) {
  $img.attr('src', $img.data('src')).removeAttr('data-src');
}

/**
 * Defer loading of images that are below the fold
 */
function lazyload() {
  // Get images that have a data-src attribue
  $('img[data-src]').each(function () {
    const $img = $(this);

    if ($img.data('lazyloadinstant')) {
      switchUrls($img);
    } else {
      unveil.init($img, {
        offset: 500,
        callback() {
          // Replace the src with data-src and cleanup the element
          switchUrls($img);
        },
      });
    }
  });
}

// ON DOCUMENT READY
$(document).ready(function () {
  lazyload();
});
