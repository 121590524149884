import { TOGGLE } from 'js/constants/button-constants';

class IntercomWrapper {
  constructor($el) {
    if (typeof Intercom !== 'function') {
      return;
    }

    $(window).on('load', function () {
      setTimeout(function () {
        window.intercomSettings = {
          app_id: 'dhcktlf7',
        };

        Intercom('boot', intercomSettings);
        // eslint-disable-next-line no-magic-numbers
      }, 5000);
    });

    $el.find(`[data-${TOGGLE.INTERCOM}]`).on('click', () => this.show());
  }

  show() {
    if (typeof Intercom !== 'function') {
      return;
    }

    Intercom('show');
  }
}

export default IntercomWrapper;
