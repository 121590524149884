const Cookie = {
  get: (name) => {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];

      while (c.charAt(0) === ' ') {
        c = c.replace(/^\s/g, '');
      }

      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  },

  set: (key, value, expires, path) => {
    let expireBy = expires;

    if (!expireBy) {
      expireBy = new Date();

      // eslint-disable-next-line no-magic-numbers
      expireBy.setDate(expireBy.getDate() + 365);
    }

    const domain = window.location.hostname.replace('www.', '');
    const cookiePath = path ? path : '/';

    document.cookie = `${key}=${value}; expires=${expireBy}; path=${cookiePath}; domain=${domain}`;
  },

  delete: (name, path) => {
    const domain = window.location.hostname.replace('www.', '');

    const cookiePath = path ? path : '/';

    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=${cookiePath}; domain=${domain}`;
  },
};

export default Cookie;
